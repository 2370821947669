
import { defineComponent, reactive, toRefs, onMounted } from 'vue';
import { TUIEnv } from '@/TUIKit/TUIPlugin';
import { TUICore } from '@/TUIKit';

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const data = reactive({
      flag:false,
      env: TUIEnv(),
      currentModel: 'conversation',
    });

    const handleCurrentConversation = (value: string) => {
      data.currentModel = value ? 'message' : 'conversation';
    };

    const TUIins = TUICore.instance

    onMounted(()=>{
      let userID:any = sessionStorage.getItem('userId');
      let userSig:any = sessionStorage.getItem('userSig');

      TUIins.login({
        userID: userID,
        userSig: userSig
      });

      data.flag = true;
    })

    return {
      ...toRefs(data),
      handleCurrentConversation,
    };
  },
});
