import { createRouter, createWebHashHistory,createWebHistory    } from "vue-router"
import Chat from '../components/Chat.vue';
import MiddlePage from '../components/MiddlePage.vue';

const routes = [
	// router参数详细看下文
	{
		path: '/',
		redirect: '/middlePage'
	},
	{
		path: "/chat",
		name: "Chat",
		component: Chat
	},
	{
		path: "/middlePage",
		name: "MiddlePage",
		component: MiddlePage
	},
]

  
export const router = createRouter({
	history: createWebHistory(),
	routes: routes
})

// export function resetRouter () {
// 	router.matcher as any = newRouter.matcher // the relevant part
// }
