
import TUIAegis from '../../../../../utils/TUIAegis';
import { defineComponent, reactive, toRefs, watchEffect } from 'vue';
import { handleErrorPrompts } from '../../../utils';


const Video = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isMute: {
      type: Boolean,
      default: () => false,
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      isMute: false,
    });

    watchEffect(() => {
      data.isMute = props.isMute;
    });
    // 发送需要上传的消息：视频
    const sendUploadMessage = async (e:any) => {
      if (e.target.files.length > 0) {
        try {
          await Video.TUIServer.sendVideoMessage(e.target);
          TUIAegis.getInstance().reportEvent({
            name: 'messageType',
            ext1: 'typeVideo',
          });
        } catch (error) {
          handleErrorPrompts(error, props);
        }
      }
      e.target.value = '';
    };

    return {
      ...toRefs(data),
      sendUploadMessage,
    };
  },
});
export default Video;
