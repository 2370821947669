
import { defineComponent, watch, reactive, toRefs, computed, ref, onMounted, nextTick } from 'vue';
import TIM from '../../../../TUICore/tim';
import { emojiUrl, emojiMap, emojiName } from '../utils/emojiMap';
import { JSONToObject } from '../utils/utils';
import TUIChat from '../index.vue';
import { Message } from 'tim-js-sdk';
export default defineComponent({
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    emojiList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['handleCollapse'],
  setup(props: any, ctx: any) {
    const { TUIServer } = TUIChat;
    const data = reactive({
      message: {} as Message,
      emojiUrl,
      emojiMap,
      emojiName,
      isCollapse: true,
      types: TIM.TYPES,
      env: TUIServer.TUICore.TUIEnv,
      type: props.type,
      emojiReacts: {},
      allMemberList: [],
    });

    const container = ref({} as HTMLElement);

    const emojiCollapseList = computed(() => {
      return data.emojiName.slice(0, 6);
    });

    const emojiExpandList = computed(() => {
      return data.emojiName.slice(6);
    });

    const select = (item: any) => {
      TUIServer.emojiReact(data.message, item);
    };

    const handleEmojiReact = (message: any) => {
      try {
        if (!message?.cloudCustomData) return;
        const reactList = JSONToObject(message?.cloudCustomData)?.messageReact?.reacts;
        if (!reactList) return;
        data.emojiReacts = reactList;
        return reactList;
      } catch (err) {
        console.warn(err);
      }
    };

    const handleEmojiReactItem = (userIDList: any) => {
      let res = '';
      userIDList?.forEach((item: any, index: any) => {
        const memberInfo = data.allMemberList?.find((member: any) => member.userID === item);
        res = res + (memberInfo ? (memberInfo as any)?.nick : item) + ', ';
      });
      if (res.length) res = res.substring(0, res.lastIndexOf(','));
      return res;
    };

    const handleAllMemberList = (message: any) => {
      const TUIChatStore = TUIServer?.currentStore;
      switch (message?.conversationType) {
        case TIM.TYPES.CONV_C2C:
          data.allMemberList = [
            {
              userID: TUIChatStore?.conversation?.userProfile?.userID,
              nick: TUIChatStore?.conversation?.userProfile?.nick,
            },
            {
              userID: TUIChatStore?.selfInfo?.userID,
              nick: TUIChatStore?.selfInfo?.nick,
            },
          ] as any;
          break;
        case TIM.TYPES.CONV_GROUP:
          data.allMemberList = TUIChatStore?.allMemberList?.memberList;
          break;
        default:
          break;
      }
    };

    watch(
      () => props.message,
      (newVal: any, oldVal: any) => {
        data.message = props.message;
        if (newVal?.conversationID !== oldVal?.conversationID) {
          handleAllMemberList(newVal);
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      ()=>data.isCollapse,
      (newVal, oldVal) => {
        if (newVal === oldVal) return;
        if (!data?.env?.isH5) return;
        ctx.emit('handleCollapse', newVal);
      }
    )

    return {
      ...toRefs(data),
      emojiCollapseList,
      emojiExpandList,
      select,
      handleEmojiReact,
      handleEmojiReactItem,
      container,
    };
  },
});
