
import { defineComponent, reactive, watchEffect, toRefs } from 'vue';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      show: false,
    });

    watchEffect(() => {
      data.show = props.show;
    });

    const toggleView = () => {
      data.show = !data.show;
      ctx.emit('update:show', data.show);
    };

    return {
      ...toRefs(data),
      toggleView,
    };
  },
});
