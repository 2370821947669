// 导入axios实例
import httpRequest from '@/utils/request';
 
// 定义接口的传参
interface UserIdParam {
    school_id: string,
    user_token: string
}

interface UserSigParam {
    school_id: string,
    user_token: string
}
 
// 获取tim chat userId
export function getUserId(param: UserIdParam) {
    return httpRequest({
        url: '/user/getuserid',
        method: 'post',
        data: param,
    })
}

// 获取tim chat userSig
export function getUserSig(param: UserSigParam) {
    return httpRequest({
        url: '/user/usersig',
        method: 'post',
        data: param,
    })
}
