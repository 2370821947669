
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { getUserId, getUserSig } from '@/apis';
import { setTokenString } from '@/utils/index';
import qs from 'qs';
import { encode, decode } from '@/utils/Base58';
import { TUICore } from '@/TUIKit';

export default defineComponent({
  name: "MiddlePage",
  setup() {
    const getCode = (byte: any) => {
      const text = byte.toString(16);
      if (byte < 16) {
        return "%0" + text;
      }
      return "%" + text;
    };

    const toString = (bytes: any) => {
      var result = "";
      for (var i = 0; i < bytes.length; ++i) {
        result += getCode(bytes[i]);
      }
      return decodeURIComponent(result);
    };
    const router = useRouter();
    const route = useRoute()

    onMounted(async () => {
      router.isReady().then(async () => {
        let { token, target } = route.query;
        let decodeTarget = toString(decode(target as any));
        let user_token = token;
        let school_id = decodeTarget.split('_')[0];
        let conversationId = decodeTarget.split('_')[1];
        let transConversationId = `${school_id}_2_${conversationId}`;
        sessionStorage.setItem("conversationId", transConversationId);

        if (!user_token && !school_id) {
          console.log("缺少参数");
          return false;
        }

        let params = {
          user_token,
          school_id
        }

        let result = setTokenString(params);

        let qsParams = qs.stringify(result);

        let userIdRes: any = await getUserId(qsParams as any)
        let userId = userIdRes.data.userId;

        let userSigRes: any = await getUserSig(qsParams as any)
        let userSig = userSigRes.data.userSin;

        sessionStorage.setItem("userId", userId);
        sessionStorage.setItem("userSig", userSig);

        router.replace('/chat');

      });
    })

    return {
      // 返回的数据
    };
  },
});
