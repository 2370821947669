
import { defineComponent, watch, reactive, toRefs, ref } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {},
      show: false,
    });

    const audio = ref(null);

    watch(
      () => props.data,
      () => {
        data.data = props.data;
      },
      { deep: true, immediate: true }
    );

    const play = () => {
      const audios = document.getElementsByTagName('audio');
      for (const audio of audios) {
        if (!audio.paused) {
          audio.pause();
          audio.load();
        }
      }
      const audioPlayer: any = audio.value;
      if (audioPlayer.paused) {
        audioPlayer.play();
        data.show = true;
      } else {
        audioPlayer.pause();
        audioPlayer.load();
        data.show = false;
      }
    };

    return {
      ...toRefs(data),
      audio,
      play,
    };
  },
});
