import Md5 from "js-md5";

export const transToken = (data:any) => {
  const myDate = new Date();
  const month = (myDate.getMonth() + 1).toString();
  const days = myDate.getDate().toString();
  const m = month.length == 1 ? '0' + month : month;
  const d = days.length == 1 ? '0' + days : days;
  const dataString = Md5(JSON.stringify(data));
  const code = Md5('Cernet@4206');
  const dateYMD = Md5(`${myDate.getFullYear()}${m}${d}`);

  const result = Md5(Md5(dataString + Md5(`${code}${dateYMD}`)));
  return result;
};

export const setTokenString = (data: any) => {
  const param = Object.assign({}, data);
  //设置初始化池
  const ordered: any = {};
  // //通过Key对对象进行排序 
  Object.keys(data).sort().forEach(function (key) {
    ordered[key] = data[key].toString();
  });
  
  return {
    ...param,
    token: transToken(ordered),
  };
}