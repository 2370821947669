
import en from './en';
import zhcn from './zh_cn';

const messages = {
  ...en,
  ...zhcn,
};

export default messages;
