import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39fa13c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade",
    onBeforeLeave: _ctx.onClose,
    onAfterLeave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('destroy')))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: _normalizeClass(["message", [_ctx.handleStyle(_ctx.type), _ctx.isH5 && 'message-h5']]),
        style: _normalizeStyle(_ctx.customStyle)
      }, [
        (!_ctx.isH5)
          ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true),
        (_ctx.isH5)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true)
      ], 6), [
        [_vShow, _ctx.visible]
      ])
    ]),
    _: 1
  }, 8, ["onBeforeLeave"]))
}