
import { defineComponent, watchEffect, reactive, toRefs, computed } from 'vue';
export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {
        progress: 0,
      },
      show: false,
    });

    watchEffect(() => {
      data.data = props.data;
    });

    const isWidth = computed(() => {
      const { width = 0, height = 0 } = (data.data as any)?.message?.payload?.imageInfoArray[0];
      return width >= height;
    });

    const toggleShow = () => {
      if (!data.data.progress) {
        data.show = !data.show;
      }
    };
    const downloadImage = (message: any) => {
      const targetImage = document.createElement('a');
      const downloadImageName = message.payload.imageInfoArray[0].instanceID;
      targetImage.setAttribute('download', downloadImageName);
      const image = new Image();
      image.src = message.payload.imageInfoArray[0].url;
      image.setAttribute('crossOrigin', 'Anonymous');
      image.onload = () => {
        targetImage.href = getImageDataURL(image);
        targetImage.click();
      };
    };

    const getImageDataURL = (image: any) => {
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, image.width, image.height);
      const extension = image.src.substring(image.src.lastIndexOf('.') + 1).toLowerCase();
      return canvas.toDataURL(`image/${extension}`, 1);
    };

    return {
      ...toRefs(data),
      toggleShow,
      isWidth,
      downloadImage,
    };
  },
});
