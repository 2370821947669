import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';

import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

const SDKAppID = 1400790818; // Your SDKAppID

// init TUIKit
const TUIKit = TUICore.init({
  SDKAppID,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

const app = createApp(App);
app.config.globalProperties.$TUIKit = TUIKit;
app.use(router).use(TUIKit).mount('#app');