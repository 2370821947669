import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23f9759c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message-video" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "progress"
}
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["message-video-box", [!_ctx.data.progress && _ctx.data.message.status === 'success' && 'message-video-cover']]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }, [
      (_ctx.data.snapshotUrl && !_ctx.data.progress)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["message-img", [_ctx.isWidth ? 'isWidth' : 'isHeight']]),
            src: _ctx.data.snapshotUrl,
            alt: ""
          }, null, 10, _hoisted_2))
        : (_openBlock(), _createElementBlock("video", {
            key: 1,
            src: _ctx.data.url
          }, null, 8, _hoisted_3)),
      (_ctx.data.progress)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("progress", {
              value: _ctx.data.progress,
              max: "1"
            }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dialog-video",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["self"]))
        }, [
          (!_ctx.isH5)
            ? (_openBlock(), _createElementBlock("header", _hoisted_6, [
                _createElementVNode("i", {
                  class: "icon icon-close",
                  onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["stop"]))
                })
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(["dialog-video-box", [_ctx.isH5 ? 'dialog-video-h5' : '']]),
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)), ["self"]))
          }, [
            _createElementVNode("video", {
              class: _normalizeClass([_ctx.isWidth ? 'isWidth' : 'isHeight']),
              src: _ctx.data.url,
              controls: "",
              autoplay: ""
            }, null, 10, _hoisted_7)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}