
import { defineComponent, watchEffect, reactive, toRefs, computed } from 'vue';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isH5: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const data = reactive({
      data: {},
      show: false,
    });

    watchEffect(() => {
      data.data = props.data;
    });

    const isWidth = computed(() => {
      const { snapshotWidth = 0, snapshotHeight = 0 } = (data.data as any)?.message?.payload;
      return snapshotWidth >= snapshotHeight;
    });

    const toggleShow = () => {
      if (!(data.data as any).progress) {
        data.show = !data.show;
      }
    };

    return {
      ...toRefs(data),
      toggleShow,
      isWidth,
    };
  },
});
