
import { defineComponent, reactive, watchEffect, toRefs } from 'vue';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: () => false,
    },
    isHeaderShow: {
      type: Boolean,
      default: () => true,
    },
    isFooterShow: {
      type: Boolean,
      default: () => true,
    },
    background: {
      type: Boolean,
      default: () => true,
    },
    title: {
      type: String,
      default: () => '',
    },
    isH5: {
      type: Boolean,
      default: () => false,
    },
    center: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props:any, ctx:any) {
    const data = reactive({
      show: false,
      isHeaderShow: true,
      isFooterShow: true,
      background: true,
      title: '',
    });

    watchEffect(() => {
      data.show = props.show;
      data.title = props.title;
      data.isHeaderShow = props.isHeaderShow;
      data.isFooterShow = props.isFooterShow;
      data.background = props.background;
    });

    const toggleView = () => {
      data.show = !data.show;
      ctx.emit('update:show', data.show);
    };

    const submit = () => {
      ctx.emit('submit');
      toggleView();
    };

    return {
      ...toRefs(data),
      toggleView,
      submit,
    };
  },
});
